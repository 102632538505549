import InvoiceForm from "./components/invoiceform";
import PrintInvoice from "./components/printinvoice";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<InvoiceForm />} />
          <Route path="/printinvoice" element={<PrintInvoice />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
