export const encodeQrText = (
  sellerName,
  sellerTRN,
  invoiceDateTime,
  totalWithVAT,
  VATTotal
) => {
  // Use TextEncoder to handle UTF-8 encoding for sallerName
  const encoder = new TextEncoder();
  const sellerNameBytes = encoder.encode(sellerName);
  const L1 = sellerNameBytes.length.toString(16);
  const sellerNameNew = Array.from(sellerNameBytes)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  //    alert("Yahan sb sahi he")
  // alert("From Global.js Total is : " + totalWithVAT + " and vatTotal is : " + VATTotal)
  const L2 = sellerTRN.length.toString(16);
  const L3 = invoiceDateTime.length.toString(16);
  const L4 = totalWithVAT.length.toString(16);
  const L5 = VATTotal.length.toString(16);

  // Ensure that length tags are two-digit hexadecimal
  const formatHex = (value) => (value.length === 1 ? "0" + value : value);

  const hex = `01${formatHex(L1)}${sellerNameNew}02${formatHex(
    L2
  )}${toHexString(sellerTRN)}03${formatHex(L3)}${toHexString(
    invoiceDateTime
  )}04${formatHex(L4)}${toHexString(totalWithVAT)}05${formatHex(
    L5
  )}${toHexString(VATTotal)}`;

  return hexToBase64(hex);
};

const toHexString = (str) => {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(str);
  return Array.from(bytes)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
};

const hexToBase64 = (strInput) => {
  try {
    const bytes = new Uint8Array(strInput.length / 2);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = parseInt(strInput.substr(i * 2, 2), 16);
    }
    const base64String = btoa(String.fromCharCode.apply(null, bytes));
    return base64String;
  } catch (error) {
    return "-1";
  }
};
