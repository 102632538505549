import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const InvoiceForm = () => {
  const [recaptchaVal, setRecaptchaVal] = useState(null);
  const navigate = useNavigate();

  const [invoiceData, setInvoiceData] = useState({
    companyNameEn: "",
    companyNameAr: "",
    CRno: null,
    VATno: null,
    invoiceNumber: null,
    invoiceDate: null,
    invoiceDueDate: null,
    quote: "",
    customerAccount: null,
    customerVAT: null,
    pONumber: null,
    store: "",
    customerName: "",
    customerAddress: "",
    customerContactPerson: null,
    notes: "",
    remarks: "",
    companyAddress: {
      additionalNumber: null,
      streetName: "",
      additionalStreet: null,
      buildingNumber: null,
      postalCode: null,
      city: "",
      provinceState: "",
      district: "",
      internationalCode: "",
    },
    items: [
      {
        itemQuantity: null,
        itemCode: "",
        itemDescription: "",
        itemPriceEach: null,
        amount: null,
      },
    ],
  });

  const formValidation = (invoiceData) => {
    for (const field in invoiceData) {
      if (["customerVAT", "notes", "remarks"].includes(field)) continue;
      if (!invoiceData[field]) {
        alert(`All fields are mandatory. \n جميع الحقول إلزامية .`);
        return false;
      }
    }

    if (invoiceData.invoiceDueDate < invoiceData.invoiceDate) {
      alert("Invioce Due date must be greater or equal to invoice date.");
      return false;
    }
    if (invoiceData.items.length === 0) {
      alert("Item Details must be entered");
      return false;
    }
    for (let index = 0; index < invoiceData.items.length; index++) {
      if (
        !invoiceData.items[index].itemCode ||
        !invoiceData.items[index].itemQuantity ||
        !invoiceData.items[index].itemPriceEach ||
        !invoiceData.items[index].itemDescription
      ) {
        alert("Item Details must be entered completely.");
        return false;
      }
    }
    if (!recaptchaVal) {
      alert(`reCAPTCHA must be filled. \nيجب ملء reCAPTCHA`);
      return false;
    }

    return true;
  };

  const recaptchahandler = () => {
    setRecaptchaVal(!recaptchaVal);
  };

  const addMorehandler = () => {
    setInvoiceData((prevData) => ({
      ...prevData,
      items: [
        ...prevData.items,
        {
          itemQuantity: 0,
          itemCode: "",
          itemDescription: "",
          itemPriceEach: 0,
          amount: 0,
        },
      ],
    }));
  };

  const handleCompanyInfo = (e) => {
    const { name, value } = e.target;

    setInvoiceData((prevData) => ({
      ...prevData,
      companyAddress: {
        ...prevData.companyAddress,
        [name]: value,
      },
    }));
  };
  const handleInvoiceData = (e, index) => {
    const { name, value } = e.target;

    setInvoiceData((prevData) => {
      if (index === -1) {
        return {
          ...prevData,
          [name]: value,
        };
      } else {
        const updatedInvoiceItemsData = [...prevData.items];
        updatedInvoiceItemsData[index] = {
          ...updatedInvoiceItemsData[index],
          [name]: value,
        };
        return {
          ...prevData,
          items: updatedInvoiceItemsData,
        };
      }
    });
  };

  const handleInvoiceDate = (e) => {
    const { name, value } = e.target;
    // alert("Date is :" + value);
    setInvoiceData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const formattedAmount = (amount) => {
    return Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };
  const deleteHandler = (indexDelete) => {
    // alert("Delete button is clicked and the index is " +  indexDelete)
    setInvoiceData((prevData) => {
      const updatedItems = prevData.items.filter(
        (_, index) => index !== indexDelete
      );
      return {
        ...prevData,
        items: updatedItems,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValidation(invoiceData)) {
      navigate("/");
    } else navigate("/printinvoice", { replace: true, state: { invoiceData } });
  };

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Paper elevation={1} sx={{ padding: 2, my: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Enter Invoice Data
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Company Name</Typography>
              <TextField
                fullWidth
                name="companyNameEn"
                value={invoiceData.companyNameEn}
                onChange={(e) => handleInvoiceData(e, -1)}
                //
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ textAlign: "right" }}>
                اسم الشركة (عربي)
              </Typography>
              <TextField
                fullWidth
                name="companyNameAr"
                value={invoiceData.companyNameAr}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>Additional Number / رقم إضافي</Typography>
              <TextField
                fullWidth
                name="additionalNumber"
                value={invoiceData.companyAddress.additionalNumber}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Street Name / اسم الشارع </Typography>
              <TextField
                fullWidth
                name="streetName"
                value={invoiceData.companyAddress.streetName}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Additional Street / شارع إضافي </Typography>
              <TextField
                fullWidth
                name="additionalStreet"
                value={invoiceData.companyAddress.additionalStreet}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Building Number/رقم المبنى</Typography>
              <TextField
                fullWidth
                name="buildingNumber"
                value={invoiceData.companyAddress.buildingNumber}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Postal Code/رمز بريدي</Typography>
              <TextField
                fullWidth
                name="postalCode"
                value={invoiceData.companyAddress.postalCode}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>City /مدينة</Typography>
              <TextField
                fullWidth
                name="city"
                value={invoiceData.companyAddress.city}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Province State / ولاية المقاطعة</Typography>
              <TextField
                fullWidth
                name="provinceState"
                value={invoiceData.companyAddress.provinceState}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>District/ يصرف </Typography>
              <TextField
                fullWidth
                name="district"
                value={invoiceData.companyAddress.district}
                onChange={handleCompanyInfo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>International Code/رمز دولي</Typography>
              <TextField
                fullWidth
                name="internationalCode"
                value={invoiceData.companyAddress.internationalCode}
                onChange={handleCompanyInfo}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>CR# / السجلات التجارية</Typography>
              <TextField
                fullWidth
                name="CRno"
                value={invoiceData.CRno}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>VAT# /ضريبة القيمة المضافة</Typography>
              <TextField
                fullWidth
                name="VATno"
                value={invoiceData.VATno}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Invoice # / رقم الفاتورة</Typography>
              <TextField
                fullWidth
                name="invoiceNumber"
                value={invoiceData.invoiceNumber}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Invoice Date/تاريخ الفاتورة</Typography>
              <TextField
                fullWidth
                type="date"
                name="invoiceDate"
                value={invoiceData.invoiceDate}
                onChange={handleInvoiceDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Due Date/تاريخ الاستحقاق</Typography>
              <TextField
                fullWidth
                type="date"
                name="invoiceDueDate"
                value={invoiceData.invoiceDueDate}
                onChange={handleInvoiceDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Quote #/يقتبس</Typography>
              <TextField
                fullWidth
                name="quote"
                value={invoiceData.quote}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Customer account/حساب العميل</Typography>
              <TextField
                fullWidth
                name="customerAccount"
                value={invoiceData.customerAccount}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>P.O. Number/رقم طلب الشراء</Typography>
              <TextField
                fullWidth
                name="pONumber"
                value={invoiceData.pONumber}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Store/محل</Typography>
              <TextField
                fullWidth
                name="store"
                value={invoiceData.store}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>Customer Name/اسم الزبون</Typography>
              <TextField
                fullWidth
                name="customerName"
                value={invoiceData.customerName}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Customer Address/عنوان العميل</Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="customerAddress"
                value={invoiceData.customerAddress}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                Customer Contact Person/شخص الاتصال بالعملاء
              </Typography>
              <TextField
                fullWidth
                name="customerContactPerson"
                value={invoiceData.customerContactPerson}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>Notes/ملحوظات</Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="notes"
                value={invoiceData.notes}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Remarks/ملاحظات</Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="remarks"
                value={invoiceData.remarks}
                onChange={(e) => handleInvoiceData(e, -1)}
              />
            </Grid>
            {/* here */}
            <Box
              component="fieldset"
              md={12}
              sm={12}
              lg={12}
              width={1100}
              padding={1}
              marginLeft={2}
            >
              <>
                <legend>
                  <h4>Item Details / تفاصيل العنصر</h4>
                </legend>
                <Grid item sx={{ paddingBottom: 3 }}>
                  <Box textAlign="left">
                    <Button variant="contained" onClick={addMorehandler}>
                      Add Item / اضافة عنصر
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      fontWeight: "bold",
                      paddingLeft: 1.5,
                      fontSize: 13,
                    }}
                  >
                    Quantity / كمية
                  </Typography>

                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: 13,
                      paddingRight: 5,
                      display: "flex",
                      justifyContent: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Item Code / رمز الصنف
                  </Typography>
                  <Typography
                    sx={{
                      flex: 3,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Description / وصف
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1.2,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Price Each / السعر كل
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      paddingLeft: 2,
                      display: "flex",
                      fontSize: 13,
                      justifyContent: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Amount / قيمة
                  </Typography>
                  <Typography
                    sx={{
                      flex: 0.8,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Actions / أجراءات
                  </Typography>
                </Grid>

                {invoiceData.items.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex" }}
                    columnGap={1}
                    marginBottom={1}
                    key={index}
                  >
                    <TextField
                      sx={{
                        flex: 0.8,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                      type="number"
                      name={`itemQuantity`}
                      value={item.itemQuantity}
                      onChange={(e) => handleInvoiceData(e, index)}
                    />
                    <TextField
                      sx={{ flex: 1.2 }}
                      name={`itemCode`}
                      type="text"
                      value={item.itemCode}
                      onChange={(e) => handleInvoiceData(e, index)}
                    />
                    <TextField
                      sx={{ flex: 3 }}
                      multiline
                      rows={2}
                      name={`itemDescription`}
                      value={item.itemDescription}
                      onChange={(e) => handleInvoiceData(e, index)}
                    />
                    <TextField
                      sx={{
                        flex: 1,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                      type="number"
                      name={`itemPriceEach`}
                      value={item.itemPriceEach}
                      onChange={(e) => handleInvoiceData(e, index)}
                    />
                    <TextField
                      sx={{ flex: 1, "& input": { textAlign: "right" } }}
                      // type="number"
                      // name={`amount`}
                      disabled
                      value={formattedAmount(
                        item.itemQuantity * item.itemPriceEach
                      )}
                      // value={item.itemQuantity * item.itemPriceEach}
                      // onChange={(e) => handleInvoiceData(e, index)}
                    />
                    <Grid
                      sx={{ flex: 0.8, paddingTop: 1, textAlign: "center" }}
                    >
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => deleteHandler(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </>
            </Box>

            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={recaptchahandler}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit / يُقدِّم
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default InvoiceForm;
