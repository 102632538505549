import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { encodeQrText } from "./global";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "./style.css"

const PrintInvoice = () => {
  const reportTemplateRef = useRef(null);
  const location = useLocation();
  const printData = location.state.invoiceData;
  const [qrcodeValue, setSetQrcodeValue] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [vatTotal, setVatTotal] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [vatTotalForQR, setVatTotalForQR] = useState(0);
  const [totalValueForQR, setTotalValueForQR] = useState(0);

  useEffect(() => {
    let amount = 0;
    printData.items.forEach((row) => {
      amount += row.itemQuantity * row.itemPriceEach;
    });

    const subtotalFormatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SAR",
    }).format(amount);
    setSubTotal(subtotalFormatted);
  }, [printData]);

  useEffect(() => {
    let amount = 0;
    printData.items.forEach((row) => {
      amount += row.itemQuantity * row.itemPriceEach;
    });
    const newVatTotal = amount * 0.15;
    const vatTotalFormatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SAR",
    }).format(newVatTotal);
    setVatTotal(vatTotalFormatted);
    setVatTotalForQR(newVatTotal);

    const totalAmount = amount + newVatTotal;
    const totalFormatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SAR",
    }).format(totalAmount);
    setTotalValue(totalFormatted);
    setTotalValueForQR(totalAmount);
  }, [printData.items]);

  useEffect(() => {
    if (printData && vatTotalForQR !== 0 && totalValueForQR !== 0) {
      setTimeout(() => {
        const newqrcodevalue = encodeQrText(
          printData.companyNameAr,
          printData.VATno,
          printData.invoiceDate,
          totalValueForQR.toString(),
          vatTotalForQR.toString()
        );
        // alert(
        //   "new Total is : " +
        //     totalValueForQR +
        //     "and new  VATTotal is: " +
        //     vatTotalForQR
        // );
        setSetQrcodeValue(newqrcodevalue);
      }, 2000);
    }
  }, [printData, totalValueForQR, vatTotalForQR]);

  // const printPageStyle = {
  //   width: "500px",
  //   height: "700px",
  //   maxHeight: "300px",
  //   overflow: "hidden",
  //   marginBottom: "15mm", // You can use "mm" to specify units
  //   marginTop: "18mm",
  //   paddingBottom: "15mm", // You can use "mm" to specify units
  //   paddingTop: "18mm",
  //   marginLeft: "5mm",
  //   marginRight: "5mm",

  // };

  // width, height

  const formattedAmount = (amount) => {
    return Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const handleDateFormat = (date) => {
    const date2 = date.toString();
    const dateNewFormat = moment(date2).format("DD-MMM-yyyy");
    return dateNewFormat;
  };

  const printInvioce = useReactToPrint({
    pageStyle: `
  @page {
    size: 210mm 297mm;
  }
`,
    content: () => reportTemplateRef.current,
    documentTitle: "InvoiceTemplate",
  });

  // const tabStyle = {
  //   width: "210mm",
  //   height: "297mm",
  //   overflow: "scroll",
  //   // marginBottom: 15,
  //   marginTop: "40mm",
  //   // marginLeft: 5,
  //   // marginRight: 5,
  // };
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ paddingTop: 2, textAlign: "center" }}>
        <Button
          className="button"
          color="primary"
          variant="contained"
          onClick={printInvioce}
        >
          Print / مطبعة
        </Button>
      </Box>

      <Box>
        <Grid
          className="report-container"
          container
          sx={{ padding: 10, width: 1180 }}
          ref={reportTemplateRef}
        >
          {printData && (
            <>
              {/* PART 1 */}
              <Grid container className="report-header">
                <Grid
                  item
                  xs={3}
                  sx={{
                    paddingLeft: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    paddingBottom: 2,
                  }}
                >
                  <Typography> CR# {printData.CRno}</Typography>
                  <Typography>VAT# {printData.VATno}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ justifyContent: "center" }}>
                  <Typography variant="h4" gutterBottom align="center">
                    {printData.companyNameEn}
                  </Typography>
                  <Typography sx={{ fontSize: 15 }} gutterBottom align="center">
                    Limited Liability Company
                  </Typography>
                  <Typography variant="h6" gutterBottom align="center">
                    {printData.companyNameAr}
                  </Typography>
                  <Typography variant="h4" align="center">
                    VAT Invoice
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    align: "center",
                    justifyContent: "center",
                  }}
                >
                  {qrcodeValue && (
                    <QRCode
                      size={150}
                      sx={{
                        height: "150",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrcodeValue}
                      viewBox={`0 0 100 100`}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container sx={{ border: "2px solid" }}>
                {/* Dates Part */}

                <Grid container sx={{ paddingLeft: 5, flex: 2 }}>
                  <Grid
                    item
                    sx={{
                      display: "block",
                      paddingRight: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingTop: 4,
                        fontWeight: "bold",
                        paddingRight: 5,
                        fontSize: "15px",
                      }}
                    >
                      Invoice #
                    </Typography>

                    <Typography
                      sx={{
                        paddingTop: 5.8,
                        paddingRight: 1,
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Invoice Date
                    </Typography>

                    <Typography
                      sx={{
                        paddingTop: 5.8,
                        paddingRight: 1,
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Due Date
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      paddingLeft: "40px",
                      paddingTop: "12px",
                    }}
                    xs={2.2}
                  >
                    <TextField
                      value={printData.invoiceNumber}
                      variant="outlined"
                      size="medium"
                      sx={{
                        width: 160,
                        paddingBottom: "8px",
                        paddingTop: "2px",
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                    <TextField
                      value={handleDateFormat(printData.invoiceDate)}
                      // value={printData.invoiceDate}
                      variant="outlined"
                      size="medium"
                      sx={{
                        width: 160,
                        paddingBottom: "8px",
                        paddingTop: "5px",
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                    <TextField
                      value={handleDateFormat(printData.invoiceDueDate)}
                      // value={printData.invoiceDueDate}
                      size="medium"
                      sx={{
                        width: 160,
                        paddingTop: "5px",
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    paddingRight: 5,
                    height: 210,
                    flex: 1,
                    border: "1px solid black",
                  }}
                >
                  <Typography sx={{ paddingLeft: 2 }}>
                    Customer Name: {printData.customerName}
                  </Typography>
                </Grid>

                <Grid sx={{ height: 210, flex: 3 }}>
                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Company Name"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyNameEn}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Additional Number"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.additionalStreet}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Street Name"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.streetName}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Additional Street"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.additionalStreet}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Building Number"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.buildingNumber}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Postal Code"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.postalCode}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"City"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.city}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"Province State"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.provinceState}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"District"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.district}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      height: 21,
                      width: "100%",
                      border: "1px solid black",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        justifyContent: "center",
                        flex: 2,
                        alignItems: "center",
                        paddingLeft: 1,
                      }}
                    >
                      {"International Code"}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        display: "flex",
                        flex: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: 1,
                      }}
                    >
                      {printData.companyAddress.internationalCode}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Customer Details */}
                <Grid
                  container
                  sx={{
                    paddingRight: 2,
                    paddingLeft: 3,
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 1,
                  }}
                >
                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        fontSize: "13px",
                        fontWeight: "bold",
                        paddingBottom: 0.5,
                      }}
                    >
                      NGT Quote #
                    </Typography>
                    <TextField
                      value={printData.quote}
                      variant="outlined"
                      size="small"
                      sx={{ width: 150 }}
                    />
                  </Grid>

                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        fontSize: "13px",
                        fontWeight: "bold",
                        paddingBottom: 0.5,
                      }}
                    >
                      Customer Account #
                    </Typography>
                    <TextField
                      value={printData.customerAccount}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: 150,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        fontSize: "13px",
                        fontWeight: "bold",
                        paddingBottom: 0.5,
                      }}
                    >
                      Customer VAT #
                    </Typography>
                    <TextField
                      value={printData.VATno}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: 150,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        fontWeight: "bold",
                        fontSize: "13px",
                        paddingBottom: 0.5,
                      }}
                    >
                      P.O Number
                    </Typography>
                    <TextField
                      value={printData.pONumber}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: 150,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        fontWeight: "bold",
                        fontSize: "13px",
                        paddingBottom: 0.5,
                      }}
                    >
                      Store
                    </Typography>
                    <TextField
                      value={printData.store}
                      variant="outlined"
                      size="small"
                      sx={{ width: 150 }}
                    />
                  </Grid>

                  <Grid item sx={{ display: "block" }} xs={2}>
                    <Typography
                      sx={{
                        paddingTop: 1,
                        fontWeight: "bold",
                        paddingLeft: 1,
                        fontSize: "13px",
                        paddingBottom: 0.5,
                      }}
                    >
                      Customer Contact
                    </Typography>
                    <TextField
                      value={printData.customerContactPerson}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: 150,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                {/* itemlist part */}
                <Grid display={"block"} sx={{ width: 1020, marginTop: "-5px" }}>
                  <Box component="fieldset">
                    <legend>
                      <h4>Item Details</h4>
                    </legend>

                    <Grid container>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flex: 0.8,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 15,
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Quantity / كمية
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          flex: 1.25,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                          Item Code / رمز الصنف
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          flex: 3,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            paddingRight: 4,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          Description / وصف
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "left",
                          fontWeight: "bold",
                        }}
                      >
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                          Price Each/السعر كل
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                          Amount/قيمة
                        </Typography>
                      </Grid>
                    </Grid>

                    {printData.items.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{ display: "flex" }}
                        columnGap={1}
                        marginBottom={1}
                        key={index}
                      >
                        <TextField
                          required
                          sx={{
                            textAlign: "right",
                            flex: 0.8,
                            "& input": {
                              textAlign: "right",
                            },
                          }}
                          value={item.itemQuantity}
                        />
                        <TextField
                          required
                          sx={{ textAlign: "left", flex: 1.25 }}
                          value={item.itemCode}
                        />
                        <TextField
                          sx={{ textAlign: "left", flex: 3 }}
                          multiline
                          maxRows={4}
                          value={item.itemDescription}
                        />
                        <TextField
                          sx={{
                            textAlign: "right",
                            flex: 1,
                            "& input": {
                              textAlign: "right",
                            },
                          }}
                          value={formattedAmount(item.itemPriceEach)}
                        />
                        <TextField
                          value={
                            formattedAmount(
                              item.itemQuantity * item.itemPriceEach
                            ) + "T"
                          }
                          sx={{
                            textAlign: "right",
                            flex: 1,
                            "& input": {
                              textAlign: "right",
                            },
                          }}
                        />
                      </Grid>
                    ))}
                  </Box>

                  <Grid sx={{ display: "flex" }}>
                    <Grid sx={{ flex: 3 }}>
                      <Grid
                        sx={{
                          height: 45,
                          border: "1px solid",
                          paddingLeft: 1,
                        }}
                      >
                        <Typography>
                          {printData.notes === ""
                            ? "Thank you for your busines."
                            : printData.notes}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          height: 95,
                          border: "1px solid",
                          paddingTop: 1,
                          paddingLeft: 1,
                        }}
                      >
                        {printData.remarks === "" ? (
                          <>
                            <Typography sx={{ fontSize: "13px" }}>
                              Bank Details:
                            </Typography>
                            <Typography sx={{ fontSize: "13px" }}>
                              Bank Name: Saudi National Bank
                            </Typography>
                            <Typography sx={{ fontSize: "13px" }}>
                              Account No# 37800000282610
                            </Typography>
                            <Typography sx={{ fontSize: "13px" }}>
                              IBAN No: SA6310000037800000282610
                            </Typography>
                          </>
                        ) : (
                          <Typography>{printData.remarks}</Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid sx={{ flex: 1.6 }}>
                      <Grid
                        item
                        sx={{
                          border: "1px solid",
                          height: 40,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ paddingLeft: 3, paddingTop: 0.5 }}
                        >
                          Subtotal
                        </Typography>
                        <Typography
                          variant="h7"
                          sx={{ paddingRight: 3, paddingTop: 1 }}
                        >
                          {subTotal}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          border: "1px solid",
                          height: 40,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ paddingTop: 0.5, paddingLeft: 3 }}
                        >
                          {" "}
                          VAT 15%
                        </Typography>
                        <Typography
                          variant="h7"
                          sx={{ paddingRight: 3, paddingTop: 1 }}
                        >
                          {vatTotal}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          border: "1px solid",
                          height: 60,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            paddingLeft: 3,
                            fontWeight: "bold",
                            paddingTop: 1.2,
                          }}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            paddingRight: 3,
                            fontWeight: "bold",
                            paddingTop: 1.3,
                            align: "right",
                          }}
                        >
                          {totalValue}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/*  footer  */}

            <Grid
            className="report-footer"
              sx={{
                marginTop: 10,
                paddingTop: 1,
                width: 1100,
                marginLeft: 2,
                marginRight: 2,
                marginBottom: 1,
                border: "2px solid",
                paddingBottom: 1,
              }}
            >
              <Typography
                sx={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                Should you have any queries concerning this Invoice please
                contact Mr. Abubakar Munawar +966 59 729 7575
              </Typography>
              <Typography
                sx={{
                  paddingLeft: 25,
                  paddingRight: 28,
                }}
              >
                This is computer generated document, and dose not required any
                signature.
              </Typography>
            </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PrintInvoice;
